// 兑换商城相关接口
import service from ".."

// 获取数据
export function shopIndex (params) {
  return service({
    method: 'GET',
    url: "/shop/index",
    params
  })
}

// 兑换商品
export function shopDeal (params) {
  return service({
    method: 'GET',
    url: "/shop/deal",
    params
  })
}