import { Row, Col, message } from 'antd'
import { Link } from 'react-router-dom'
import footerImg from '@/assets/footer/footer-icon.png'
import logoIcon from '@/assets/footer/logo.png'
import './index.scss'

function Footer () {
  return (
    <div className='footer-box'>
      {/* <Row className='footer-contact'>
        <Col>
          <img src={logoIcon} className='footer-logo' />
          <p className='footer-tip'>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。</p>
          <p className='footer-tip'>适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</p>
        </Col>
        <Col>
          <Row className='contact-info'>
            <Col>
              <h6 className='footer-title'>QQ客服</h6>
              <p className='footer-qq'>8345678888</p>
            </Col>
            <Col>
              <h6 className='footer-title'>服务热线</h6>
              <p className='footer-tel'>400-888-8888</p>
            </Col>
          </Row>
        </Col>
      </Row> */}
      <Row justify='center' className='bottom-menu' align='middle'>
        <Col>
          <Link to='/about#part-1'>关于我们</Link>
        </Col>
        <Col>
          <Link to='/about#part-2'>服务条款</Link>
        </Col>
        <Col>
          <Link to='/about#part-3'>联系我们</Link>
        </Col>
        <Col>
          <Link to='/feedback'>反馈建议</Link>
        </Col>
        <Col>
          <Link to='/extend'>推广中心</Link>
        </Col>
        <Col>
          <Link to='/help'>帮助中心</Link>
        </Col>
      </Row>
      <Row justify='center' align='middle' className='footer-info'>
        <Col>备案号：粤ICP备2020003715号-2</Col>
        <Col>ICP证：粤B2-20200542</Col>
        <Col>文网文：粤网文 [2020) 2155088号</Col>
        <Col>Copyright 2023-2026</Col>
        <Col>
          <a href='https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32068402000318' target='_blank'>
            <img src={footerImg} />
            <span>公网安备32068402052102号</span>
          </a>
        </Col>
      </Row>
      <Row justify='center' align='middle' className='footer-info'>
        <Col>抵制不良游戏，拒绝盗版游戏。注意自我保护，谨防受骗上当。适度游戏益脑，沉迷游戏伤身。合理安排时间，享受健康生活。</Col>
      </Row>
    </div>
  )
}

export default Footer