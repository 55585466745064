// 关于我们相关接口
import service from ".."

// 获取数据
export function aboutInfo (params) {
  return service({
    method: 'GET',
    url: "/about/info",
    params
  })
}