const getStylePath = (style) => {
  let pathName = 'quick'
  if (style == 30) {
    pathName = 'quick'
  }
  if (style == 60) {
    pathName = 'happy'
  }
  if (style == 210) {
    pathName = 'fortune'
  }
  if (style == 90) {
    pathName = 'luck'
  }
  return pathName
}

export {
  getStylePath
}