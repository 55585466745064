// 公告相关接口
import service from ".."

// 获取公告列表数据
export function messageLists (params) {
  return service({
    method: 'GET',
    url: "/message/lists",
    params
  })
}

// 获取公告详情
export function messageInfo (params) {
  return service({
    method: 'GET',
    url: "/message/info",
    params
  })
}