const shortNumber = (nStr) => {
  nStr += ''  //数字转换为字符 
  let x = nStr.split('.')  //按照小数点分隔
  let x1 = x[0]  //整数部分
  let x2 = x.length > 1 ? '.' + x[1] : ''  //小数部分
  var rgx = /(\d+)(\d{3})/  //正则式定义
  while (rgx.test(x1)) {  //正则式匹配
    x1 = x1.replace(rgx, '$1' + ',' + '$2')   //正则式替换
  }
  return x1 + x2
}

export {
  shortNumber
}