// 抽奖相关接口
import service from ".."

// 获取数据
export function luckyInfo (params) {
  return service({
    method: 'GET',
    url: "/lucky/info",
    params
  })
}


// 执行抽奖
export function luckyDeal (params) {
  return service({
    method: 'GET',
    url: "/lucky/deal",
    params
  })
}