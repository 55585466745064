// 个人中心相关接口
import service from ".."


// 顶部用户信息
export function guessInfo (params) {
  return service({
    method: 'GET',
    url: "/guess/info",
    params
  })
}


// 用户信息
export function ucenterInfo (params) {
  return service({
    method: 'GET',
    url: "/ucenter/info",
    params
  })
}


// 银鱼兑换列表
export function exchangeInfo (params) {
  return service({
    method: 'GET',
    url: "/exchange/info",
    params
  })
}

// 获取金鱼记录
export function logDiamond (params) {
  return service({
    method: 'GET',
    url: "/ucenter/log_diamond",
    params
  })
}

// 获取银鱼记录
export function logGold (params) {
  return service({
    method: 'GET',
    url: "/ucenter/log_gold",
    params
  })
}

// 获取兑换记录
export function shopLists (params) {
  return service({
    method: 'GET',
    url: "/shop/lists",
    params
  })
}

// 获取抽奖记录
export function luckyLog (params) {
  return service({
    method: 'GET',
    url: "/lucky/log",
    params
  })
}

// 存储银鱼
export function saveGold (params) {
  return service({
    method: 'GET',
    url: "/ucenter/save_gold",
    params
  })
}

// 提取银鱼
export function dislodgeGold (params) {
  return service({
    method: 'GET',
    url: "/ucenter/dislodge_gold",
    params
  })
}

// 使用银鱼卡
export function cardDeal (params) {
  return service({
    method: 'GET',
    url: "/card/deal",
    params
  })
}

// 银鱼卡管理
export function cardLogs (params) {
  return service({
    method: 'GET',
    url: "/card/logs",
    params
  })
}


// 执行签到
export function signDeal (params) {
  return service({
    method: 'GET',
    url: "/sign/deal",
    params
  })
}

// 设置昵称
export function setUname (params) {
  return service({
    method: 'GET',
    url: "/ucenter/set_uname",
    params
  })
}

// 系统头像
export function defaultHead (params) {
  return service({
    method: 'GET',
    url: "/ucenter/default_head",
    params
  })
}

// 设置头像
export function setHead (params) {
  return service({
    method: 'GET',
    url: "/ucenter/set_head",
    params
  })
}

// 修改密码
export function resetPwdByPhone (params) {
  return service({
    method: 'GET',
    url: "/ucenter/reset_pwd_by_phone",
    params
  })
}


// 修改支付密码
export function setPayPwdSub (params) {
  return service({
    method: 'GET',
    url: "/ucenter/set_pay_pwd",
    params
  })
}


// 实名认证
export function auth (params) {
  return service({
    method: 'GET',
    url: "/ucenter/auth",
    params
  })
}

// 支付密码确认
export function checkPayPwd (params) {
  return service({
    method: 'GET',
    url: "/ucenter/check_pay_pwd",
    params
  })
}

// 取消订单
export function shopRevoke (params) {
  return service({
    method: 'GET',
    url: "/shop/revoke",
    params
  })
}

// 取消奖品
export function cancelPrize (params) {
  return service({
    method: 'GET',
    url: "/lucky/cancel_prize",
    params
  })
}

// 提现信息
export function withdrawInfo (params) {
  return service({
    method: 'GET',
    url: "/withdraw/info",
    params
  })
}

// 绑定支付宝
export function aliAuth (params) {
  return service({
    method: 'GET',
    url: "/ucenter/ali_auth",
    params
  })
}

// 执行提现
export function withdrawDeal (params) {
  return service({
    method: 'GET',
    url: "/withdraw/deal",
    params
  })
}

// 使用银鱼卡规则
export function cardRule (params) {
  return service({
    method: 'GET',
    url: "/card/rule",
    params
  })
}