// 反馈建议相关接口
import service from ".."

// 提交
export function feedBackSave (params) {
  return service({
    method: 'GET',
    url: "/feed_back/save",
    params
  })
}