// VIP相关接口
import service from ".."

// 获取首页数据
export function vipIndex (params) {
  return service({
    method: 'GET',
    url: "/vip/index_new",
    params
  })
}

// 确定充值
export function vipDealBuy (params) {
  return service({
    method: 'GET',
    url: "/vip/deal_buy",
    params
  })
}

// 查询好友
export function checkUinfo (params) {
  return service({
    method: 'GET',
    url: "/vip/check_uinfo",
    params
  })
}