import { makeAutoObservable } from 'mobx'
import { commonInfo, guessInfo } from '@/request/api'
import { getStylePath, getUrlParam } from '@/utils'


class common28Store {
  banner = []
  bet_auto = 0
  bet_track = 0
  current = { id: '', open_time: '', remain_time: 0, open_limit_time: '' }
  last = { code: '', code1: '', code2: '', code3: '', id: '' }
  systime = ''
  uinfo = {
    bank_gold: '',
    gold: '',
    head: '',
    id: '',
    uname: '',
    vip: [],
    down_qrcode: ''
  }
  style = getUrlParam('style') || 30
  stylePath = getStylePath(getUrlParam('style')) || 'quick'
  constructor() {
    makeAutoObservable(this)
  }

  // 获取用户信息
  setUserInfo = async (callBack) => {
    const res = await guessInfo()
    if (res.code == 1) {
      // message.warning(res.msg)
    } else if (res.code == -1) {

    } else {
      this.uinfo = res.data
      document.getElementById('cgwl-kefu-form').setAttribute('action', `https://kf.tiaotiaoyu.com/index/index/home?visiter_id=${res.data.id}=&visiter_name=${res.data.id}&avatar=&business_id=2&groupid=0`)
      callBack && callBack()
    }
  }

  // 获取公共数据
  setData = async (type) => {
    let stylePath = this.stylePath
    if (type) {
      stylePath = getStylePath(type)
    }
    const res = await commonInfo({
      stylePath
    })
    if (res.code == 1) {
      // message.warning(res.msg)
    } else {
      this.banner = res.data.banner
      this.bet_auto = res.data.bet_auto
      this.bet_track = res.data.bet_track
      this.current = res.data.current
      this.last = res.data.last
      this.systime = res.data.systime
    }
  }

  // 设置服务器时间
  setSystime = () => {
    this.systime = this.systime + 1
  }
  // 设置截止倒计时
  setCurrentTime = () => {
    this.current.remain_time = this.current.remain_time - 1
    this.current.open_limit_time = this.current.open_limit_time - 1
  }

  // 设置样式
  setStyle = (curStyle) => {
    this.style = curStyle
    this.stylePath = getStylePath(curStyle)
  }
}

export default common28Store