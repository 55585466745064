import { Route, Routes, Navigate } from 'react-router-dom'
import { lazy, Suspense } from 'react'
import { HistoryRouter, history } from '@/utils/history'
import Layout from '@/views/Layout'
const Home = lazy(() => import('@/views/Home'))
const Home28 = lazy(() => import('@/views/Home28'))
const Puzzle = lazy(() => import('@/views/Puzzle'))
const Vip = lazy(() => import('@/views/Vip'))
const Login = lazy(() => import('@/views/Login'))
const Register = lazy(() => import('@/views/Register'))
const Extend = lazy(() => import('@/views/Extend'))
const Trend = lazy(() => import('@/views/Trend'))
const Set28 = lazy(() => import('@/views/Set28'))
const AutoAdd = lazy(() => import('@/views/AutoAdd'))
const AutoJoin = lazy(() => import('@/views/AutoJoin'))
const MyJoin = lazy(() => import('@/views/MyJoin'))
const JoinDetail = lazy(() => import('@/views/JoinDetail'))
const PlayIntro = lazy(() => import('@/views/PlayIntro'))
const UCenter = lazy(() => import('@/views/UCenter'))
const Forget = lazy(() => import('@/views/Forget'))
const Lucky = lazy(() => import('@/views/Lucky'))
const Notice = lazy(() => import('@/views/Notice'))
const NoticeDetail = lazy(() => import('@/views/NoticeDetail'))
const Help = lazy(() => import('@/views/Help'))
const FeedBack = lazy(() => import('@/views/FeedBack'))
const NewTask = lazy(() => import('@/views/NewTask'))
const DayTask = lazy(() => import('@/views/DayTask'))
const Game = lazy(() => import('@/views/Game'))
const Play = lazy(() => import('@/views/Play'))
const Shop = lazy(() => import('@/views/Shop'))
const About = lazy(() => import('@/views/About'))


function App () {
  return (
    <HistoryRouter history={history}>
      <Suspense
        fallback={
          <div
            style={{
              textAlign: 'center',
              marginTop: 200
            }}
          >
            loading...
          </div>
        }
      >
        <Routes>
          <Route path='/' element={<Layout />} exact={false}>
            <Route path='/register' element={<Register />}></Route>
            <Route index element={<Home />}></Route>
            <Route path='/forget' element={<Forget />}></Route>
            <Route path='/about' element={<About />}></Route>
            <Route path='/feedback' element={<FeedBack />}></Route>
            <Route path='/game' element={<Game />}></Route>
            <Route path='/play' element={<Play />}></Route>
            <Route path='/puzzle' element={localStorage.getItem('fishUtoken') ? <Puzzle /> : <Navigate to="/" />}></Route>
            <Route path='/help' element={localStorage.getItem('fishUtoken') ? <Help /> : <Navigate to="/" />}></Route>
            <Route path='/notice' element={localStorage.getItem('fishUtoken') ? <Notice /> : <Navigate to="/" />}></Route>
            <Route path='/noticeDetail' element={localStorage.getItem('fishUtoken') ? <NoticeDetail /> : <Navigate to="/" />}></Route>
            <Route path='/newtask' element={localStorage.getItem('fishUtoken') ? <NewTask /> : <Navigate to="/" />}></Route>
            <Route path='/daytask' element={localStorage.getItem('fishUtoken') ? <DayTask /> : <Navigate to="/" />}></Route>
            <Route path='/home28' element={localStorage.getItem('fishUtoken') ? <Home28 /> : <Navigate to="/" />}></Route>
            <Route path='/extend' element={localStorage.getItem('fishUtoken') ? <Extend /> : <Navigate to="/" />}></Route>
            <Route path='/trend' element={localStorage.getItem('fishUtoken') ? <Trend /> : <Navigate to="/" />}></Route>
            <Route path='/set28' element={localStorage.getItem('fishUtoken') ? <Set28 /> : <Navigate to="/" />}></Route>
            <Route path='/autoadd' element={localStorage.getItem('fishUtoken') ? <AutoAdd /> : <Navigate to="/" />}></Route>
            <Route path='/autojoin' element={localStorage.getItem('fishUtoken') ? <AutoJoin /> : <Navigate to="/" />}></Route>
            <Route path='/myjoin' element={localStorage.getItem('fishUtoken') ? <MyJoin /> : <Navigate to="/" />}></Route>
            <Route path='/joindetail' element={localStorage.getItem('fishUtoken') ? <JoinDetail /> : <Navigate to="/" />}></Route>
            <Route path='/playintro' element={localStorage.getItem('fishUtoken') ? <PlayIntro /> : <Navigate to="/" />}></Route>
            <Route path='/lucky' element={localStorage.getItem('fishUtoken') ? <Lucky /> : <Navigate to="/" />}></Route>
            <Route path='/shop' element={localStorage.getItem('fishUtoken') ? <Shop /> : <Navigate to="/" />}></Route>
            <Route path='/vip' element={localStorage.getItem('fishUtoken') ? <Vip /> : <Navigate to="/" />}></Route>
            <Route path='/login' element={localStorage.getItem('fishUtoken') ? <Login /> : <Navigate to="/" />}></Route>
            <Route path='/ucenter' element={localStorage.getItem('fishUtoken') ? <UCenter /> : <Navigate to="/" />}></Route>
          </Route>
        </Routes>
      </Suspense>
    </HistoryRouter>
  )
}

export default App
