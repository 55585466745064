// 游戏试玩相关接口
import service from ".."

// 获取数据
export function indexAlpinia (params) {
  return service({
    method: 'GET',
    url: "/index/alpinia",
    params
  })
}