// 帮助中心相关接口
import service from ".."

// 获取数据
export function faqLists (params) {
  return service({
    method: 'GET',
    url: "/faq/lists",
    params
  })
}