// 推广中心相关接口
import service from ".."

// 获取数据
export function inviteInfo (params) {
  return service({
    method: 'GET',
    url: "/invite/info",
    params
  })
}

// 排行版
export function inviteRank (params) {
  return service({
    method: 'GET',
    url: "/invite/rank",
    params
  })
}



// 推广红包
export function redpacketLists (params) {
  return service({
    method: 'GET',
    url: "/redpacket/lists",
    params
  })
}



// 生成红包
export function redpacketAdd (params) {
  return service({
    method: 'GET',
    url: "/redpacket/add",
    params
  })
}



// 使用红包
export function redpacketDeal (params) {
  return service({
    method: 'GET',
    url: "/redpacket/deal",
    params
  })
}



// 导出红包
export function redpacketDown (params) {
  return service({
    method: 'GET',
    url: "/redpacket/down",
    params
  })
}



// 撤销红包
export function redpacketRevoke (params) {
  return service({
    method: 'GET',
    url: "/redpacket/revoke",
    params
  })
}



// 红包领取记录
export function redpacketRecords (params) {
  return service({
    method: 'GET',
    url: "/redpacket/records",
    params
  })
}



// 推广范文
export function inviteEssay (params) {
  return service({
    method: 'GET',
    url: "/invite/essay",
    params
  })
}



// 我的好友
export function inviteFriends (params) {
  return service({
    method: 'GET',
    url: "/invite/friends",
    params
  })
}



// 奖励明细
export function invitePrize (params) {
  return service({
    method: 'GET',
    url: "/invite/prize",
    params
  })
}