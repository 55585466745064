import { Row, Col, message, Popover } from 'antd'
import { observer } from 'mobx-react-lite'
import { Link } from 'react-router-dom'
import { useStore } from '@/store'
import { useEffect, useState } from 'react'
import { loginOut } from '@/request/api'
import './index.scss'
const down_qrcode = `https://i.tiaotiaoyu.vip/static/img/down.png`

function TopLogin (props) {
  const { common28Store, utokenStore } = useStore()
  useEffect(() => {
    common28Store.setUserInfo()
  }, [])

  const loginOutFun = async () => {
    const res = await loginOut()
    if (res.code == 1) {
      message.warning(res.msg)
    } else {
      localStorage.removeItem('fishUtoken')
      localStorage.removeItem('fishisShowLuck')
      utokenStore.setUtoken()
      window.location.href = '/'
    }
  }

  return (
    <div className='top-login'>
      <Row justify='space-between' align='middle'>
        <Col>
          {
            !props.utoken &&
            <Row className='left-part'>
              <Col>
                <Link to='/'>登陆</Link>
              </Col>
              <Col>
                <Link to='/register'>注册</Link>
              </Col>
            </Row>
          }
          {
            props.utoken &&
            <Row className='left-part logined' align='middle'>
              <Col><Link to='/ucenter' className='black'>ID：{common28Store.uinfo.id}</Link></Col>
              <Col>
                <Link to='/ucenter' className='black'>{common28Store.uinfo.uname}</Link>
                {
                  common28Store.uinfo.vip.map(item => {
                    return <img src={item} key={item} className='vip-icon' />
                  })
                }
              </Col>
              <Col>
                <span>金鱼：{common28Store.uinfo.diamond}</span>
              </Col>
              <Col>
                <span>银鱼：{common28Store.uinfo.gold}</span></Col>
              <Col className='red' onClick={loginOutFun}>
                <span>退出</span>
                <span className='iconfont'>&#xe6fd;</span>
              </Col>
            </Row>
          }
        </Col>
        <Col>
          <Row className='right-part'>
            <Col>
              <Popover placement="bottom"
                title=''
                content={
                  <Row className='top-download' align='middle'>
                    <Col className='code-box'>
                      <img src={down_qrcode} />
                    </Col>
                    <Col>
                      <div className='font1'>更多精彩</div>
                      <div className='font2'>扫码下载APP</div>
                    </Col>
                  </Row>
                }
                trigger="click">
                <>
                  <span className="iconfont phone-icon">&#xe6f6;</span>
                  <span>下载APP</span>
                </>
              </Popover>
            </Col>
            <Col>
              <Link to='/lucky'>抽奖</Link>
            </Col>
            <Col>
              <Link to='/shop'>兑换商城</Link>
            </Col>
            <Col>
              <Link to='/help'>帮助</Link>
            </Col>
            <Col>
              <Link to='/ucenter'>个人中心</Link>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default observer(TopLogin)