import 'core-js/es'
import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
// import 'antd/dist/antd.min.css'
import './App.scss'
import { StyleProvider, legacyLogicalPropertiesTransformer } from '@ant-design/cssinjs'


const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode>
  <StyleProvider hashPriority="high" transformers={[legacyLogicalPropertiesTransformer]}>
    <div className='app-container'>
      <App />
    </div>
  </StyleProvider>
  // </React.StrictMode>
)
