import TopNav from "@/components/TopNav"
import TopLogin from "@/components/TopLogin"
import Footer from "@/components/Footer"
import { Outlet } from 'react-router-dom'
import './index.scss'
import { observer } from 'mobx-react-lite'
import { useStore } from '@/store'

function Layout () {
  const { utokenStore } = useStore()
  utokenStore.setUtoken()
  return (
    <div className="layout-container">
      {
        <TopLogin utoken={utokenStore.utoken} />
      }
      <TopNav />
      <Outlet></Outlet>
      <Footer />
    </div>
  )
}

export default observer(Layout)