// 任务专区相关接口
import service from ".."

// 获取数据-新人任务
export function taskNews (params) {
  return service({
    method: 'GET',
    url: "/task/news",
    params
  })
}

// 获取数据-每日任务
export function taskDay (params) {
  return service({
    method: 'GET',
    url: "/task/daily",
    params
  })
}

// 领取任务奖励
export function taskDeal (params) {
  return service({
    method: 'GET',
    url: "/task/deal_prize",
    params
  })
}
