import { Row, Col, Dropdown } from 'antd'
import { useState, useEffect } from 'react'
import { useLocation, Link } from 'react-router-dom'
import logoIcon from '@/assets/logo.png'
import './index.scss'

function TopNav () {
  const { pathname } = useLocation()
  const [defaultSelected, setDefaultSelected] = useState(pathname)
  useEffect(() => {
    setDefaultSelected(pathname)
    // setBodyBg(pathname)
    window.scrollTo(0, 0)
  }, [pathname])

  const setBodyBg = (curPath) => {
    if (curPath == '/puzzle') {
      document.querySelector("body").style.backgroundColor = '#eef9ff'
    } else {
      document.querySelector("body").style.backgroundColor = '#eee'
    }
  }

  return (
    <div className='top-nav'>
      <Row justify='space-between' className='top-box-container' align='middle'>
        <Col className='logo-box'>
          <Link to='/'><img src={logoIcon} className='logo-icon' /></Link>
        </Col>
        <Col>
          <Row className='top-menu'>
            <Col className={defaultSelected == '/' ? 'current' : ''}>
              <Link to='/'>首页</Link>
            </Col>
            <Col className={defaultSelected == '/newtask' ? 'current' : ''}>
              <Link to='/newtask'>新人任务</Link>
            </Col>
            <Col className={defaultSelected == '/daytask' ? 'current' : ''}>
              <Link to='/daytask'>每日任务</Link>
            </Col>
            {/* <Col className={(defaultSelected == '/newtask' || defaultSelected == '/daytask') ? 'current' : ''}>
              <Dropdown
                menu={{
                  items: [
                    {
                      key: 'newtask',
                      label: (
                        <Link to='/newtask'>新人任务</Link>
                      ),
                    },
                    {
                      key: 'daytask',
                      label: (
                        <Link to='/daytask'>每日任务</Link>
                      ),
                    }
                  ]
                }}
                placement="bottom"
              >
                <Link>任务专区</Link>
              </Dropdown>
            </Col> */}
            <Col className={defaultSelected == '/game' ? 'current' : ''}>
              <Link to='/game'>休闲试玩</Link>
            </Col>
            <Col className={defaultSelected == '/play' ? 'current' : ''}>
              <Link to='/play'>娱乐大厅</Link>
            </Col>
            <Col className={defaultSelected == '/vip' ? 'current' : ''}>
              <Link to='/vip'>会员中心</Link>
            </Col>
            <Col className={defaultSelected == '/extend' ? 'current' : ''}>
              <Link to='/extend'>推广专区</Link>
            </Col>
          </Row>
        </Col>
      </Row >
    </div >
  )
}

export default TopNav