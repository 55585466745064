// 兑换相关接口
import service from ".."


// 兑换
export function exchangeDeal (params) {
  return service({
    method: 'GET',
    url: `/exchange/deal`,
    params
  })
}


// 兑换记录
export function exchangeLogs (params) {
  return service({
    method: 'GET',
    url: `/exchange/logs`,
    params
  })
}