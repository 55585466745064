const TOKEN_KEY = 'post_me'

const getToken = () => {
  return localStorage.getToken(TOKEN_KEY)
}

const setToken = token => {
  return localStorage.setItem(TOKEN_KEY, token)
}

const clearToken = () => {
  return localStorage.removeItem(TOKEN_KEY)
}

export {
  getToken,
  setToken,
  clearToken
}