import { makeAutoObservable } from 'mobx'
import { getUrlParam } from '@/utils'


class common28Store {
  utoken = ''
  constructor() {
    makeAutoObservable(this)
  }

  // 获取数据
  setUtoken = () => {
    this.utoken = getUrlParam('utoken') || localStorage.getItem('fishUtoken')
  }
}

export default common28Store