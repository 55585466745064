import axios from 'axios'
import { createBrowserHistory } from 'history'
import { getUrlParam } from '@/utils'
const history = createBrowserHistory()
// window.globalT = Math.floor(Math.random() * 13 * 1000000000000)
window.baseURL = 'https://i.tiaotiaoyu.com'
let service = axios.create({
  baseURL: window.baseURL,
  timeout: 3000
})


// 添加请求拦截器
service.interceptors.request.use((config) => {
  if (getUrlParam('utoken')) {
    localStorage.setItem('fishUtoken', getUrlParam('utoken'))
  }
  const utoken = localStorage.getItem('fishUtoken')
  config.params = { utoken, ...config.params }
  return config
}, (error) => {
  return Promise.reject(error)
})

// 添加响应拦截器
service.interceptors.response.use((response) => {
  if (response.data.code == -1) {
    localStorage.removeItem('fishUtoken')
    if (window.location.pathname == '/' || window.location.pathname == '/register' || window.location.pathname == '/forget') {
      return response.data
    }
    window.location.replace('/')
  }
  return response.data
}, (error) => {
  return Promise.reject(error)
})

export default service