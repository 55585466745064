import React from "react"
import common28Store from './common28.store'
import utokenStore from './utoken.store'

class RootStore {
  constructor() {
    this.common28Store = new common28Store()
    this.utokenStore = new utokenStore()
  }
}

const StoreContext = React.createContext(new RootStore())
const useStore = () => React.useContext(StoreContext)
export { useStore }