// 28相关接口
import service from ".."

// 负分清零
export function zeroClear (params) {
  return service({
    method: 'GET',
    url: `/guess/clear_zero`,
    params
  })
}

// 排行
export function crazyRank (params) {
  return service({
    method: 'GET',
    url: `/guess/rank`,
    params
  })
}

// 获取公共信息
export function commonInfo (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}/common_info`,
    params
  })
}

// 退出登录
export function loginOut (params) {
  return service({
    method: 'GET',
    url: "/ucenter/login_out",
    params
  })
}


// 首页数据
export function indexPc (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}/index_pc`,
    params
  })
}


// 参与记录
export function joinPc (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}/join_pc`,
    params
  })
}

// 走势图
export function trend (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}/trend`,
    params
  })
}


// 自动参与
export function quickAutoInfo (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_auto/info`,
    params
  })
}


// 编辑模式数据
export function happyPlanInfo (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_plan/info`,
    params
  })
}


// 上期投注信息
export function lastInfo (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}/last_info`,
    params
  })
}


// 添加模式
export function planAdd (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_plan/add`,
    params
  })
}


//删除模式
export function planDel (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_plan/del`,
    params
  })
}


// 模式列表
export function planLists (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_plan/lists`,
    params
  })
}


// 修改模式
export function planEdit (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_plan/edit`,
    params
  })
}


// 投注
export function order (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}/order`,
    params
  })
}


// 自动追号页面
export function trackInfo (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_track/info`,
    params
  })
}


// 开始自动追号
export function trackStart (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_track/start`,
    params
  })
}


// 停止自动追号
export function trackStop (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_track/stop`,
    params
  })
}


// 开始自动参与
export function autoSart (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_auto/start`,
    params
  })
}


// 停止自动参与
export function autoStop (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_auto/stop`,
    params
  })
}


// 设置自动投注输赢后模式信息
export function setWinLose (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}_plan/set_win_lose`,
    params
  })
}


// 指定期数详情
export function quickInfo (params) {
  return service({
    method: 'GET',
    url: `/${params.stylePath}/info`,
    params
  })
}


// 获取竞猜工资
export function wages (params) {
  return service({
    method: 'GET',
    url: `/guess/wages`,
    params
  })
}


// 领取竞猜工资
export function dealWages (params) {
  return service({
    method: 'GET',
    url: `/guess/deal_wages`,
    params
  })
}


// 获取救济银鱼
export function relief (params) {
  return service({
    method: 'GET',
    url: `/guess/relief`,
    params
  })
}


// 领取救济银鱼
export function dealRelief (params) {
  return service({
    method: 'GET',
    url: `/guess/deal_relief`,
    params
  })
}